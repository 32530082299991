import React from "react";
import { graphql } from "gatsby";

import styled from "../components/styled/ThemeProvider";
import { FormInput, Button } from "../components/styled";

import { Layout, SEO } from "../components";

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function encode(data: any) {
  return Object.keys(data)
    .map(
      (key: string) =>
        // @ts-ignore
        encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
    )
    .join("&");
}

interface IProps {
  data: {
    site: {
      siteMetadata: {
        author: string;
        intro: string;
      };
    };
  };
}

const About: React.FC<IProps> = ({
  data: {
    site: {
      siteMetadata: { author, intro },
    },
  },
}) => {
  const [isSent, setIsSent] = React.useState(false);

  const [state, setState] = React.useState<any>({});

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const sendForm = (event: any) => {
    event.preventDefault();

    const form = event.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => setIsSent(true))
      .catch((error) => console.log(error));
  };

  return (
    <Layout>
      <SEO title={author} />
      <div>
        <h1>{author}</h1>
        <p>{intro}</p>
        {isSent ? (
          <div>
            <h3>Thank you for your message, I will get back to you soon.</h3>
          </div>
        ) : (
          <form
            name="contact-form"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={sendForm}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact-form" />
            <p hidden>
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <FormInput>
              <label htmlFor="name">Name*</label>
              <input type="text" name="name" onChange={handleChange} required />
            </FormInput>

            <FormInput>
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                required
              />
            </FormInput>

            <FormInput>
              <label htmlFor="message">Message*</label>
              <textarea
                name="message"
                cols={30}
                rows={10}
                onChange={handleChange}
                required
              />
            </FormInput>

            <RightAlign>
              <Button type="submit" text="Send" />
            </RightAlign>
          </form>
        )}
      </div>
    </Layout>
  );
};

export default About;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        author
        intro
      }
    }
  }
`;
